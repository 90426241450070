export const svgDevCommiting = <svg width="500" height="436" viewBox="0 0 500 436" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_18_147)">
<path d="M450.819 226.645C450.885 280.534 430.055 332.35 392.709 371.2C390.458 373.557 388.145 375.858 385.772 378.102C382.157 381.541 378.42 384.846 374.562 388.019C338.468 417.756 293.409 434.466 246.654 435.453C245.097 435.489 243.533 435.506 241.963 435.506C204.675 435.506 157.775 415.59 115.214 388.019C111.693 385.738 108.203 383.404 104.744 381.017C103.128 379.912 101.519 378.791 99.9179 377.655C83.5026 366.064 67.9212 353.335 53.2886 339.561C43.7081 330.57 34.7242 320.964 26.3939 310.803C-6.30894 270.464 -13.522 234.116 33.1014 226.645C38.6214 225.763 43.9788 224.82 49.1736 223.817C56.6983 222.371 63.8996 220.809 70.7776 219.132C80.0423 216.88 88.7466 214.438 96.8905 211.807C99.6299 210.925 102.307 210.026 104.921 209.109C143.567 195.535 169.433 177.834 187.039 158.629C190.026 155.378 192.779 152.088 195.299 148.758C199.154 143.691 202.656 138.364 205.78 132.816C210.092 125.106 213.714 117.031 216.603 108.684L216.762 108.225C230.77 67.8156 226.508 30.3335 241.963 17.7885C308.027 -35.8423 390.275 40.5329 428.88 130.599C430.826 135.138 432.656 139.703 434.371 144.297C435.141 146.348 435.884 148.404 436.599 150.463C438.48 155.83 440.187 161.211 441.719 166.606C442.307 168.664 442.868 170.719 443.401 172.773C443.971 174.989 444.518 177.199 445.023 179.41C448.779 195.594 450.819 211.554 450.819 226.645Z" fill="#F2F2F2"/>
<path d="M246.654 435.454C245.097 435.489 243.533 435.506 241.963 435.506C204.675 435.506 157.775 415.59 115.214 388.019C111.693 385.738 108.203 383.404 104.744 381.017L107.778 362.559L199.608 350.366L245.696 385.609L246.654 435.454Z" fill="#2F2E41"/>
<path d="M251.075 105.845H237.989C237.584 105.845 237.195 106.006 236.909 106.292C236.622 106.579 236.461 106.968 236.461 107.373V120.465C236.461 120.665 236.501 120.863 236.578 121.048C236.655 121.233 236.767 121.401 236.909 121.543C237.051 121.684 237.22 121.796 237.405 121.873C237.59 121.949 237.789 121.988 237.989 121.987H251.075C251.48 121.987 251.868 121.827 252.154 121.542C252.441 121.256 252.602 120.869 252.604 120.465V107.373C252.602 106.968 252.441 106.58 252.155 106.293C251.868 106.007 251.48 105.846 251.075 105.845Z" fill="white"/>
<path d="M251.075 128.154H237.989C237.584 128.154 237.195 128.315 236.909 128.602C236.622 128.888 236.461 129.277 236.461 129.682V142.774C236.461 142.974 236.501 143.173 236.578 143.358C236.655 143.543 236.767 143.711 236.909 143.852C237.051 143.994 237.22 144.106 237.405 144.182C237.59 144.258 237.789 144.297 237.989 144.297H251.075C251.48 144.297 251.868 144.137 252.154 143.851C252.441 143.566 252.602 143.178 252.604 142.774V129.682C252.602 129.277 252.441 128.889 252.155 128.603C251.868 128.316 251.48 128.155 251.075 128.154Z" fill="#E6E6E6"/>
<path d="M251.075 150.463H237.989C237.584 150.464 237.195 150.625 236.909 150.911C236.622 151.198 236.461 151.587 236.461 151.992V165.078C236.461 165.278 236.5 165.477 236.577 165.663C236.653 165.849 236.766 166.017 236.908 166.159C237.05 166.301 237.218 166.414 237.404 166.491C237.59 166.567 237.789 166.606 237.989 166.606H251.075C251.48 166.606 251.869 166.445 252.156 166.158C252.442 165.872 252.603 165.483 252.604 165.078V151.992C252.602 151.587 252.441 151.199 252.155 150.912C251.868 150.626 251.48 150.465 251.075 150.463Z" fill="#E6E6E6"/>
<path d="M252.58 174.031C252.515 173.679 252.329 173.36 252.054 173.131C251.779 172.901 251.433 172.774 251.075 172.773H237.989C237.584 172.773 237.195 172.934 236.909 173.221C236.622 173.507 236.461 173.896 236.461 174.301V187.387C236.461 187.588 236.5 187.787 236.577 187.972C236.653 188.158 236.766 188.327 236.908 188.469C237.05 188.611 237.218 188.723 237.404 188.8C237.59 188.877 237.789 188.916 237.989 188.915H251.075C251.481 188.915 251.869 188.754 252.156 188.468C252.442 188.181 252.604 187.792 252.604 187.387V174.301C252.605 174.21 252.597 174.12 252.58 174.031Z" fill="#E6E6E6"/>
<path d="M251.669 195.2C251.481 195.122 251.279 195.082 251.075 195.082H237.989C237.789 195.082 237.59 195.121 237.404 195.198C237.218 195.274 237.05 195.387 236.908 195.529C236.766 195.671 236.653 195.84 236.577 196.025C236.5 196.211 236.461 196.41 236.461 196.611V209.696C236.46 209.897 236.499 210.097 236.576 210.282C236.652 210.468 236.765 210.637 236.907 210.779C237.049 210.921 237.218 211.034 237.403 211.11C237.589 211.187 237.788 211.226 237.989 211.225H251.075C251.481 211.225 251.869 211.064 252.156 210.777C252.442 210.49 252.604 210.102 252.604 209.696V196.611C252.603 196.31 252.514 196.016 252.348 195.765C252.182 195.514 251.946 195.318 251.669 195.2Z" fill="#E6E6E6"/>
<path d="M278.564 105.845H265.478C265.073 105.845 264.684 106.006 264.397 106.292C264.111 106.579 263.95 106.968 263.949 107.373V120.465C263.95 120.665 263.989 120.863 264.066 121.048C264.143 121.233 264.256 121.401 264.398 121.543C264.54 121.684 264.708 121.796 264.894 121.873C265.079 121.949 265.277 121.988 265.478 121.987H278.564C278.968 121.987 279.356 121.827 279.643 121.542C279.929 121.256 280.091 120.869 280.092 120.465V107.373C280.091 106.968 279.93 106.58 279.643 106.293C279.357 106.007 278.969 105.846 278.564 105.845Z" fill="#E6E6E6"/>
<path d="M278.564 128.154H265.478C265.073 128.154 264.684 128.315 264.397 128.602C264.111 128.888 263.95 129.277 263.949 129.682V142.774C263.95 142.974 263.989 143.173 264.066 143.358C264.143 143.543 264.256 143.711 264.398 143.852C264.54 143.994 264.708 144.106 264.894 144.182C265.079 144.258 265.277 144.297 265.478 144.297H278.564C278.968 144.297 279.356 144.137 279.643 143.851C279.929 143.566 280.091 143.178 280.092 142.774V129.682C280.091 129.277 279.93 128.889 279.643 128.603C279.357 128.316 278.969 128.155 278.564 128.154Z" fill="#FFB802"/>
<path d="M278.564 150.463H265.478C265.073 150.464 264.684 150.625 264.397 150.911C264.111 151.198 263.95 151.587 263.949 151.992V165.078C263.949 165.278 263.988 165.477 264.065 165.663C264.142 165.849 264.254 166.017 264.396 166.159C264.538 166.301 264.707 166.414 264.892 166.491C265.078 166.567 265.277 166.606 265.478 166.606H278.564C278.969 166.606 279.358 166.445 279.644 166.158C279.931 165.872 280.092 165.483 280.092 165.078V151.992C280.091 151.587 279.93 151.199 279.643 150.912C279.357 150.626 278.969 150.465 278.564 150.463Z" fill="#FFB802"/>
<path d="M278.564 172.773H265.478C265.157 172.773 264.845 172.874 264.586 173.062C264.326 173.25 264.132 173.515 264.032 173.819C263.977 173.974 263.95 174.137 263.949 174.301V187.387C263.949 187.588 263.988 187.787 264.065 187.972C264.142 188.158 264.254 188.327 264.396 188.469C264.538 188.611 264.707 188.723 264.892 188.8C265.078 188.877 265.277 188.916 265.478 188.915H278.564C278.969 188.915 279.358 188.754 279.644 188.468C279.931 188.181 280.092 187.792 280.092 187.387V174.301C280.091 173.896 279.93 173.508 279.643 173.222C279.357 172.935 278.969 172.774 278.564 172.773Z" fill="#FFB802"/>
<path d="M278.564 195.082H265.478C265.277 195.082 265.078 195.121 264.892 195.198C264.707 195.274 264.538 195.387 264.396 195.529C264.254 195.671 264.142 195.84 264.065 196.025C263.988 196.211 263.949 196.41 263.949 196.611V209.696C263.949 209.897 263.988 210.096 264.065 210.282C264.142 210.467 264.254 210.636 264.396 210.778C264.538 210.92 264.707 211.033 264.892 211.109C265.078 211.186 265.277 211.225 265.478 211.225H278.564C278.969 211.225 279.358 211.064 279.644 210.777C279.931 210.49 280.092 210.102 280.092 209.696V196.611C280.092 196.205 279.931 195.817 279.644 195.53C279.358 195.243 278.969 195.082 278.564 195.082Z" fill="#E6E6E6"/>
<path d="M306.052 105.845H292.966C292.561 105.845 292.172 106.006 291.886 106.292C291.599 106.579 291.438 106.968 291.438 107.373V120.465C291.438 120.665 291.478 120.863 291.555 121.048C291.632 121.233 291.744 121.401 291.886 121.543C292.028 121.684 292.197 121.796 292.382 121.873C292.567 121.949 292.766 121.988 292.966 121.987H306.052C306.457 121.987 306.845 121.827 307.131 121.542C307.418 121.256 307.579 120.869 307.581 120.465V107.373C307.579 106.968 307.418 106.58 307.132 106.293C306.845 106.007 306.457 105.846 306.052 105.845Z" fill="#FFB802"/>
<path d="M306.052 128.154H292.966C292.561 128.154 292.172 128.315 291.886 128.602C291.599 128.888 291.438 129.277 291.438 129.682V142.774C291.438 142.974 291.478 143.173 291.555 143.358C291.632 143.543 291.744 143.711 291.886 143.852C292.028 143.994 292.197 144.106 292.382 144.182C292.567 144.258 292.766 144.297 292.966 144.297H306.052C306.457 144.297 306.845 144.137 307.131 143.851C307.418 143.566 307.579 143.178 307.581 142.774V129.682C307.579 129.277 307.418 128.889 307.132 128.603C306.845 128.316 306.457 128.155 306.052 128.154Z" fill="#FFB802"/>
<path d="M306.052 150.463H292.966C292.561 150.464 292.172 150.625 291.886 150.911C291.599 151.198 291.438 151.587 291.438 151.992V165.078C291.438 165.278 291.477 165.477 291.554 165.663C291.63 165.849 291.743 166.017 291.885 166.159C292.027 166.301 292.195 166.414 292.381 166.491C292.567 166.567 292.766 166.606 292.966 166.606H306.052C306.457 166.606 306.846 166.445 307.133 166.158C307.419 165.872 307.58 165.483 307.581 165.078V151.992C307.579 151.587 307.418 151.199 307.132 150.912C306.845 150.626 306.457 150.465 306.052 150.463Z" fill="#E6E6E6"/>
<path d="M306.052 172.773H292.966C292.561 172.773 292.172 172.934 291.886 173.221C291.599 173.507 291.438 173.896 291.438 174.301V187.387C291.438 187.588 291.477 187.787 291.553 187.972C291.63 188.158 291.743 188.327 291.885 188.469C292.027 188.611 292.195 188.723 292.381 188.8C292.567 188.877 292.766 188.916 292.966 188.915H306.052C306.457 188.915 306.846 188.754 307.133 188.468C307.419 188.181 307.58 187.792 307.581 187.387V174.301C307.579 173.896 307.418 173.508 307.132 173.222C306.845 172.935 306.457 172.774 306.052 172.773Z" fill="#FFB802"/>
<path d="M306.052 195.082H292.966C292.766 195.082 292.567 195.121 292.381 195.198C292.195 195.274 292.027 195.387 291.885 195.529C291.743 195.671 291.63 195.84 291.554 196.025C291.477 196.211 291.438 196.41 291.438 196.611V209.696C291.438 209.897 291.477 210.096 291.553 210.282C291.63 210.467 291.743 210.636 291.885 210.778C292.027 210.92 292.195 211.033 292.381 211.109C292.567 211.186 292.766 211.225 292.966 211.225H306.052C306.457 211.225 306.846 211.064 307.133 210.777C307.419 210.49 307.58 210.102 307.581 209.696V196.611C307.58 196.205 307.419 195.817 307.133 195.53C306.846 195.243 306.457 195.082 306.052 195.082Z" fill="#FFB802"/>
<path d="M333.541 105.845H320.455C320.05 105.845 319.661 106.006 319.374 106.292C319.088 106.579 318.927 106.968 318.926 107.373V120.465C318.927 120.665 318.966 120.863 319.043 121.048C319.12 121.233 319.233 121.401 319.375 121.543C319.517 121.684 319.685 121.796 319.871 121.873C320.056 121.949 320.254 121.988 320.455 121.987H333.541C333.945 121.987 334.333 121.827 334.62 121.542C334.906 121.256 335.068 120.869 335.069 120.465V107.373C335.068 106.968 334.907 106.58 334.62 106.293C334.334 106.007 333.946 105.846 333.541 105.845Z" fill="#FFB802"/>
<path d="M333.541 128.154H320.455C320.05 128.154 319.661 128.315 319.374 128.602C319.088 128.888 318.927 129.277 318.926 129.682V142.774C318.927 142.974 318.966 143.173 319.043 143.358C319.12 143.543 319.233 143.711 319.375 143.852C319.517 143.994 319.685 144.106 319.871 144.182C320.056 144.258 320.254 144.297 320.455 144.297H333.541C333.945 144.297 334.333 144.137 334.62 143.851C334.906 143.566 335.068 143.178 335.069 142.774V129.682C335.068 129.277 334.907 128.889 334.62 128.603C334.334 128.316 333.946 128.155 333.541 128.154Z" fill="white"/>
<path d="M333.541 150.463H320.455C320.05 150.464 319.661 150.625 319.374 150.911C319.088 151.198 318.927 151.587 318.926 151.992V165.078C318.926 165.278 318.965 165.477 319.042 165.663C319.119 165.849 319.231 166.017 319.373 166.159C319.515 166.301 319.684 166.414 319.869 166.491C320.055 166.567 320.254 166.606 320.455 166.606H333.541C333.946 166.606 334.335 166.445 334.621 166.158C334.908 165.872 335.069 165.483 335.069 165.078V151.992C335.068 151.587 334.907 151.199 334.62 150.912C334.334 150.626 333.946 150.465 333.541 150.463Z" fill="white"/>
<path d="M333.541 172.773H320.455C320.05 172.773 319.661 172.934 319.374 173.221C319.088 173.507 318.927 173.896 318.926 174.301V187.387C318.926 187.588 318.965 187.787 319.042 187.972C319.119 188.158 319.231 188.327 319.373 188.469C319.515 188.611 319.684 188.723 319.869 188.8C320.055 188.877 320.254 188.916 320.455 188.915H333.541C333.946 188.915 334.335 188.754 334.621 188.468C334.908 188.181 335.069 187.792 335.069 187.387V174.301C335.068 173.896 334.907 173.508 334.62 173.222C334.334 172.935 333.946 172.774 333.541 172.773Z" fill="#E6E6E6"/>
<path d="M333.541 195.082H320.455C320.254 195.082 320.055 195.121 319.869 195.198C319.684 195.274 319.515 195.387 319.373 195.529C319.231 195.671 319.119 195.84 319.042 196.025C318.965 196.211 318.926 196.41 318.926 196.611V209.696C318.926 209.897 318.965 210.096 319.042 210.282C319.119 210.467 319.231 210.636 319.373 210.778C319.515 210.92 319.684 211.033 319.869 211.109C320.055 211.186 320.254 211.225 320.455 211.225H333.541C333.946 211.225 334.335 211.064 334.621 210.777C334.908 210.49 335.069 210.102 335.069 209.696V196.611C335.069 196.205 334.908 195.817 334.621 195.53C334.335 195.243 333.946 195.082 333.541 195.082Z" fill="#FFB802"/>
<path d="M361.029 105.845H347.937C347.533 105.846 347.146 106.008 346.86 106.294C346.575 106.581 346.415 106.969 346.415 107.373V120.465C346.415 120.868 346.575 121.256 346.861 121.541C347.146 121.827 347.534 121.987 347.937 121.987H361.029C361.433 121.987 361.821 121.827 362.108 121.542C362.394 121.256 362.556 120.869 362.558 120.465V107.373C362.556 106.968 362.395 106.58 362.109 106.293C361.822 106.007 361.434 105.846 361.029 105.845Z" fill="white"/>
<path d="M361.029 128.154H347.937C347.533 128.155 347.146 128.317 346.86 128.604C346.575 128.89 346.415 129.278 346.415 129.682V142.774C346.415 143.178 346.575 143.565 346.861 143.851C347.146 144.136 347.534 144.297 347.937 144.297H361.029C361.433 144.297 361.821 144.137 362.108 143.851C362.394 143.566 362.556 143.178 362.558 142.774V129.682C362.556 129.277 362.395 128.889 362.109 128.603C361.822 128.316 361.434 128.155 361.029 128.154Z" fill="white"/>
<path d="M361.029 150.463H347.937C347.533 150.465 347.146 150.626 346.86 150.913C346.575 151.199 346.415 151.587 346.415 151.992V165.078C346.414 165.278 346.453 165.477 346.529 165.662C346.606 165.847 346.718 166.016 346.859 166.158C347.001 166.3 347.169 166.412 347.354 166.489C347.539 166.566 347.737 166.606 347.937 166.606H361.029C361.434 166.606 361.823 166.445 362.11 166.158C362.396 165.872 362.557 165.483 362.558 165.078V151.992C362.556 151.587 362.395 151.199 362.109 150.912C361.822 150.626 361.434 150.465 361.029 150.463Z" fill="white"/>
<path d="M361.029 172.773H347.937C347.533 172.774 347.146 172.936 346.86 173.222C346.575 173.509 346.415 173.897 346.415 174.301V187.387C346.414 187.587 346.453 187.786 346.529 187.971C346.606 188.157 346.718 188.325 346.859 188.467C347.001 188.609 347.169 188.722 347.354 188.799C347.539 188.876 347.737 188.915 347.937 188.915H361.029C361.434 188.915 361.823 188.754 362.11 188.468C362.396 188.181 362.557 187.792 362.558 187.387V174.301C362.556 173.896 362.395 173.508 362.109 173.222C361.822 172.935 361.434 172.774 361.029 172.773Z" fill="#FFB802"/>
<path d="M361.029 195.082H347.937C347.737 195.082 347.539 195.122 347.354 195.199C347.169 195.276 347.001 195.389 346.859 195.531C346.718 195.673 346.606 195.841 346.529 196.026C346.453 196.212 346.414 196.41 346.415 196.611V209.696C346.414 209.897 346.453 210.095 346.529 210.281C346.606 210.466 346.718 210.634 346.859 210.776C347.001 210.918 347.169 211.031 347.354 211.108C347.539 211.185 347.737 211.225 347.937 211.225H361.029C361.434 211.225 361.823 211.064 362.11 210.777C362.396 210.49 362.557 210.102 362.558 209.696V196.611C362.557 196.205 362.396 195.817 362.11 195.53C361.823 195.243 361.434 195.082 361.029 195.082Z" fill="#E6E6E6"/>
<path d="M388.518 105.845H375.426C375.022 105.846 374.634 106.008 374.349 106.294C374.063 106.581 373.903 106.969 373.903 107.373V120.465C373.903 120.868 374.064 121.256 374.349 121.541C374.635 121.827 375.022 121.987 375.426 121.987H388.518C388.922 121.987 389.31 121.827 389.597 121.542C389.883 121.256 390.045 120.869 390.046 120.465V107.373C390.045 106.968 389.884 106.58 389.597 106.293C389.311 106.007 388.923 105.846 388.518 105.845Z" fill="white"/>
<path d="M388.518 128.154H375.426C375.022 128.155 374.634 128.317 374.349 128.604C374.063 128.89 373.903 129.278 373.903 129.682V142.774C373.903 143.178 374.064 143.565 374.349 143.851C374.635 144.136 375.022 144.297 375.426 144.297H388.518C388.922 144.297 389.31 144.137 389.597 143.851C389.883 143.566 390.045 143.178 390.046 142.774V129.682C390.045 129.277 389.884 128.889 389.597 128.603C389.311 128.317 388.923 128.155 388.518 128.154Z" fill="white"/>
<path d="M388.518 150.463H375.426C375.022 150.465 374.634 150.626 374.349 150.913C374.063 151.199 373.903 151.587 373.903 151.992V165.078C373.903 165.278 373.942 165.477 374.018 165.662C374.094 165.847 374.206 166.016 374.348 166.158C374.489 166.3 374.657 166.412 374.842 166.489C375.027 166.566 375.226 166.606 375.426 166.606H388.518C388.923 166.606 389.312 166.445 389.598 166.158C389.885 165.872 390.046 165.483 390.046 165.078V151.992C390.045 151.587 389.884 151.199 389.597 150.912C389.311 150.626 388.923 150.465 388.518 150.463Z" fill="#FFB802"/>
<path d="M388.518 172.773H375.426C375.022 172.774 374.634 172.936 374.349 173.222C374.063 173.509 373.903 173.897 373.903 174.301V187.387C373.903 187.587 373.942 187.786 374.018 187.971C374.094 188.157 374.206 188.325 374.348 188.467C374.489 188.609 374.657 188.722 374.842 188.799C375.027 188.876 375.226 188.915 375.426 188.915H388.518C388.923 188.915 389.312 188.754 389.598 188.468C389.885 188.181 390.046 187.792 390.046 187.387V174.301C390.045 173.896 389.884 173.508 389.597 173.222C389.311 172.935 388.923 172.774 388.518 172.773Z" fill="#E6E6E6"/>
<path d="M388.518 195.082H375.426C375.226 195.082 375.027 195.122 374.842 195.199C374.657 195.276 374.489 195.389 374.348 195.531C374.206 195.673 374.094 195.841 374.018 196.026C373.942 196.212 373.903 196.41 373.903 196.611V209.696C373.903 209.897 373.942 210.095 374.018 210.281C374.094 210.466 374.206 210.634 374.348 210.776C374.489 210.918 374.657 211.031 374.842 211.108C375.027 211.185 375.226 211.225 375.426 211.225H388.518C388.923 211.225 389.312 211.064 389.598 210.777C389.885 210.49 390.046 210.102 390.046 209.696V196.611C390.046 196.205 389.885 195.817 389.598 195.53C389.312 195.243 388.923 195.082 388.518 195.082Z" fill="white"/>
<path d="M416.006 105.845H402.914C402.51 105.846 402.123 106.008 401.837 106.294C401.552 106.581 401.392 106.969 401.392 107.373V120.465C401.392 120.868 401.552 121.256 401.838 121.541C402.123 121.827 402.511 121.987 402.914 121.987H416.006C416.41 121.987 416.798 121.827 417.085 121.542C417.371 121.256 417.533 120.869 417.535 120.465V107.373C417.533 106.968 417.372 106.58 417.086 106.293C416.799 106.007 416.411 105.846 416.006 105.845Z" fill="#FFB802"/>
<path d="M416.006 128.154H402.914C402.51 128.155 402.123 128.317 401.837 128.604C401.552 128.89 401.392 129.278 401.392 129.682V142.774C401.392 143.178 401.552 143.565 401.838 143.851C402.123 144.136 402.511 144.297 402.914 144.297H416.006C416.41 144.297 416.798 144.137 417.085 143.851C417.371 143.566 417.533 143.178 417.535 142.774V129.682C417.533 129.277 417.372 128.889 417.086 128.603C416.799 128.317 416.411 128.155 416.006 128.154Z" fill="#FFB802"/>
<path d="M416.006 150.463H402.914C402.51 150.465 402.123 150.626 401.837 150.913C401.552 151.199 401.392 151.587 401.392 151.992V165.078C401.391 165.278 401.43 165.477 401.506 165.662C401.583 165.847 401.695 166.016 401.836 166.158C401.978 166.3 402.146 166.412 402.331 166.489C402.516 166.566 402.714 166.606 402.914 166.606H416.006C416.411 166.606 416.8 166.445 417.087 166.158C417.373 165.872 417.534 165.483 417.535 165.078V151.992C417.533 151.587 417.372 151.199 417.086 150.912C416.799 150.626 416.411 150.465 416.006 150.463Z" fill="#E6E6E6"/>
<path d="M416.006 172.773H402.914C402.51 172.774 402.123 172.936 401.837 173.222C401.552 173.509 401.392 173.897 401.392 174.301V187.387C401.391 187.587 401.43 187.786 401.506 187.971C401.583 188.157 401.695 188.325 401.836 188.467C401.978 188.609 402.146 188.722 402.331 188.799C402.516 188.876 402.714 188.915 402.914 188.915H416.006C416.411 188.915 416.8 188.754 417.087 188.468C417.373 188.181 417.534 187.792 417.535 187.387V174.301C417.533 173.896 417.372 173.508 417.086 173.222C416.799 172.935 416.411 172.774 416.006 172.773Z" fill="white"/>
<path d="M416.006 195.082H402.914C402.714 195.082 402.516 195.122 402.331 195.199C402.146 195.276 401.978 195.389 401.836 195.531C401.695 195.673 401.583 195.841 401.506 196.026C401.43 196.212 401.391 196.41 401.392 196.611V209.696C401.391 209.897 401.43 210.095 401.506 210.281C401.583 210.466 401.695 210.634 401.836 210.776C401.978 210.918 402.146 211.031 402.331 211.108C402.516 211.185 402.714 211.225 402.914 211.225H416.006C416.411 211.225 416.8 211.064 417.087 210.777C417.373 210.49 417.534 210.102 417.535 209.696V196.611C417.534 196.205 417.373 195.817 417.087 195.53C416.8 195.243 416.411 195.082 416.006 195.082Z" fill="#FFB802"/>
<path d="M443.495 105.845H430.403C429.998 105.846 429.611 106.008 429.326 106.294C429.04 106.581 428.88 106.969 428.88 107.373V120.465C428.88 120.868 429.041 121.256 429.326 121.541C429.612 121.827 429.999 121.987 430.403 121.987H443.495C443.899 121.987 444.287 121.827 444.573 121.542C444.86 121.256 445.022 120.869 445.023 120.465V107.373C445.022 106.968 444.86 106.58 444.574 106.293C444.288 106.007 443.9 105.846 443.495 105.845Z" fill="#FFB802"/>
<path d="M443.495 128.154H430.403C429.998 128.155 429.611 128.317 429.326 128.604C429.04 128.89 428.88 129.278 428.88 129.682V142.774C428.88 143.178 429.041 143.565 429.326 143.851C429.612 144.136 429.999 144.297 430.403 144.297H443.495C443.899 144.297 444.287 144.137 444.573 143.851C444.86 143.566 445.022 143.178 445.023 142.774V129.682C445.022 129.277 444.86 128.889 444.574 128.603C444.288 128.317 443.9 128.155 443.495 128.154Z" fill="#FFB802"/>
<path d="M443.495 150.463H430.403C429.998 150.465 429.611 150.626 429.326 150.913C429.04 151.199 428.88 151.587 428.88 151.992V165.078C428.88 165.278 428.919 165.477 428.995 165.662C429.071 165.847 429.183 166.016 429.325 166.158C429.466 166.3 429.634 166.412 429.819 166.489C430.004 166.566 430.202 166.606 430.403 166.606H443.495C443.9 166.606 444.288 166.445 444.575 166.158C444.862 165.872 445.023 165.483 445.023 165.078V151.992C445.022 151.587 444.86 151.199 444.574 150.912C444.288 150.626 443.9 150.465 443.495 150.463Z" fill="#E6E6E6"/>
<path d="M443.495 172.773H430.403C429.998 172.774 429.611 172.936 429.326 173.222C429.04 173.509 428.88 173.897 428.88 174.301V187.387C428.88 187.587 428.919 187.786 428.995 187.971C429.071 188.157 429.183 188.325 429.325 188.467C429.466 188.609 429.634 188.722 429.819 188.799C430.004 188.876 430.202 188.915 430.403 188.915H443.495C443.9 188.915 444.289 188.754 444.575 188.468C444.862 188.181 445.023 187.792 445.023 187.387V174.301C445.022 173.896 444.86 173.508 444.574 173.222C444.288 172.935 443.9 172.774 443.495 172.773Z" fill="#FFB802"/>
<path d="M443.495 195.082H430.403C430.202 195.082 430.004 195.122 429.819 195.199C429.634 195.276 429.466 195.389 429.325 195.531C429.183 195.673 429.071 195.841 428.995 196.026C428.919 196.212 428.88 196.41 428.88 196.611V209.696C428.88 209.897 428.919 210.095 428.995 210.281C429.071 210.466 429.183 210.634 429.325 210.776C429.466 210.918 429.634 211.031 429.819 211.108C430.004 211.185 430.202 211.225 430.403 211.225H443.495C443.9 211.225 444.289 211.064 444.575 210.777C444.862 210.49 445.023 210.102 445.023 209.696V196.611C445.023 196.205 444.862 195.817 444.575 195.53C444.289 195.243 443.9 195.082 443.495 195.082Z" fill="#FFB802"/>
<path d="M470.983 105.845H457.891C457.487 105.846 457.1 106.008 456.814 106.294C456.529 106.581 456.369 106.969 456.369 107.373V120.465C456.369 120.868 456.529 121.256 456.815 121.541C457.1 121.827 457.488 121.987 457.891 121.987H470.983C471.387 121.987 471.775 121.827 472.062 121.542C472.348 121.256 472.51 120.869 472.511 120.465V107.373C472.51 106.968 472.349 106.58 472.062 106.293C471.776 106.007 471.388 105.846 470.983 105.845Z" fill="#E6E6E6"/>
<path d="M470.983 128.154H457.891C457.487 128.155 457.1 128.317 456.814 128.604C456.529 128.89 456.369 129.278 456.369 129.682V142.774C456.369 143.178 456.529 143.565 456.815 143.851C457.1 144.136 457.488 144.297 457.891 144.297H470.983C471.387 144.297 471.775 144.137 472.062 143.851C472.348 143.566 472.51 143.178 472.511 142.774V129.682C472.51 129.277 472.349 128.889 472.062 128.603C471.776 128.317 471.388 128.155 470.983 128.154Z" fill="#FFB802"/>
<path d="M470.983 150.463H457.891C457.487 150.465 457.1 150.626 456.814 150.913C456.529 151.199 456.369 151.587 456.369 151.992V165.078C456.368 165.278 456.407 165.477 456.483 165.662C456.56 165.847 456.672 166.016 456.813 166.158C456.955 166.3 457.123 166.412 457.308 166.489C457.493 166.566 457.691 166.606 457.891 166.606H470.983C471.388 166.606 471.777 166.445 472.064 166.158C472.35 165.872 472.511 165.483 472.511 165.078V151.992C472.51 151.587 472.349 151.199 472.062 150.912C471.776 150.626 471.388 150.465 470.983 150.463Z" fill="#FFB802"/>
<path d="M470.983 172.773H457.891C457.487 172.774 457.1 172.936 456.814 173.222C456.529 173.509 456.369 173.897 456.369 174.301V187.387C456.368 187.587 456.407 187.786 456.483 187.971C456.56 188.157 456.672 188.325 456.813 188.467C456.955 188.609 457.123 188.722 457.308 188.799C457.493 188.876 457.691 188.915 457.891 188.915H470.983C471.388 188.915 471.777 188.754 472.064 188.468C472.35 188.181 472.511 187.792 472.511 187.387V174.301C472.51 173.896 472.349 173.508 472.062 173.222C471.776 172.935 471.388 172.774 470.983 172.773Z" fill="#FFB802"/>
<path d="M470.983 195.082H457.891C457.691 195.082 457.493 195.122 457.308 195.199C457.123 195.276 456.955 195.389 456.813 195.531C456.672 195.673 456.56 195.841 456.483 196.026C456.407 196.212 456.368 196.41 456.369 196.611V209.696C456.368 209.897 456.407 210.095 456.483 210.281C456.56 210.466 456.672 210.634 456.813 210.776C456.955 210.918 457.123 211.031 457.308 211.108C457.493 211.185 457.691 211.225 457.891 211.225H470.983C471.388 211.225 471.777 211.064 472.064 210.777C472.35 210.49 472.511 210.102 472.511 209.696V196.611C472.511 196.205 472.35 195.817 472.064 195.53C471.777 195.243 471.388 195.082 470.983 195.082Z" fill="#E6E6E6"/>
<path d="M498.471 105.845H485.38C484.975 105.846 484.588 106.008 484.303 106.294C484.017 106.581 483.857 106.969 483.857 107.373V120.465C483.857 120.868 484.018 121.256 484.303 121.541C484.589 121.827 484.976 121.987 485.38 121.987H498.471C498.876 121.987 499.264 121.827 499.55 121.542C499.837 121.256 499.999 120.869 500 120.465V107.373C499.999 106.968 499.837 106.58 499.551 106.293C499.265 106.007 498.876 105.846 498.471 105.845Z" fill="#E6E6E6"/>
<path d="M498.471 128.154H485.38C484.975 128.155 484.588 128.317 484.303 128.604C484.017 128.89 483.857 129.278 483.857 129.682V142.774C483.857 143.178 484.018 143.565 484.303 143.851C484.589 144.136 484.976 144.297 485.38 144.297H498.471C498.876 144.297 499.264 144.137 499.55 143.851C499.837 143.566 499.999 143.178 500 142.774V129.682C499.999 129.277 499.837 128.889 499.551 128.603C499.265 128.317 498.876 128.155 498.471 128.154Z" fill="#E6E6E6"/>
<path d="M498.471 150.463H485.38C484.975 150.465 484.588 150.626 484.303 150.913C484.017 151.199 483.857 151.587 483.857 151.992V165.078C483.857 165.278 483.896 165.477 483.972 165.662C484.048 165.847 484.16 166.016 484.302 166.158C484.443 166.3 484.611 166.412 484.796 166.489C484.981 166.566 485.179 166.606 485.38 166.606H498.471C498.877 166.606 499.265 166.445 499.552 166.158C499.839 165.872 500 165.483 500 165.078V151.992C499.999 151.587 499.837 151.199 499.551 150.912C499.265 150.626 498.876 150.465 498.471 150.463Z" fill="#FFB802"/>
<path d="M498.471 172.773H485.38C484.975 172.774 484.588 172.936 484.303 173.222C484.017 173.509 483.857 173.897 483.857 174.301V187.387C483.857 187.587 483.896 187.786 483.972 187.971C484.048 188.157 484.16 188.325 484.302 188.467C484.443 188.609 484.611 188.722 484.796 188.799C484.981 188.876 485.179 188.915 485.38 188.915H498.471C498.877 188.915 499.265 188.754 499.552 188.468C499.839 188.181 500 187.792 500 187.387V174.301C499.999 173.896 499.837 173.508 499.551 173.222C499.265 172.935 498.876 172.774 498.471 172.773Z" fill="#E6E6E6"/>
<path d="M498.471 195.082H485.38C485.179 195.082 484.981 195.122 484.796 195.199C484.611 195.276 484.443 195.389 484.302 195.531C484.16 195.673 484.048 195.841 483.972 196.026C483.896 196.212 483.857 196.41 483.857 196.611V209.696C483.857 209.897 483.896 210.095 483.972 210.281C484.048 210.466 484.16 210.634 484.302 210.776C484.443 210.918 484.611 211.031 484.796 211.108C484.981 211.185 485.179 211.225 485.38 211.225H498.471C498.877 211.225 499.265 211.064 499.552 210.777C499.839 210.49 500 210.102 500 209.696V196.611C500 196.205 499.839 195.817 499.552 195.53C499.265 195.243 498.877 195.082 498.471 195.082Z" fill="#E6E6E6"/>
<path d="M197.578 388.02H197.213L197.525 387.979L197.578 388.02Z" fill="#2F2E41"/>
<path d="M166.256 144.059C188.438 144.059 206.42 126.077 206.42 103.895C206.42 81.7128 188.438 63.7308 166.256 63.7308C144.074 63.7308 126.092 81.7128 126.092 103.895C126.092 126.077 144.074 144.059 166.256 144.059Z" fill="#FFB6B6"/>
<path d="M201.429 74.4472C201.429 74.4472 211.934 54.3653 188.823 52.5396C188.823 52.5396 169.121 34.6672 149.039 49.2723C149.039 49.2723 138.085 49.2723 132.095 61.6678C132.095 61.6678 123.48 58.4004 121.587 67.1446C121.587 67.1446 115.283 85.401 121.587 101.832C127.891 118.262 129.985 120.088 129.985 120.088C129.985 120.088 119.626 85.6402 144.84 83.8145C170.054 81.9888 198.269 66.2319 200.37 86.3138C202.471 106.396 205.633 111.344 205.633 111.344C205.633 111.344 225.591 82.6626 201.429 74.4472Z" fill="#2F2E41"/>
<path d="M270.151 202.877L270.022 203.559L268.57 211.225L258.535 264.25L257.941 267.372L256.695 273.973L254.99 282.979L251.21 302.937L250.129 308.657L249.723 310.803L249.582 311.555L248.459 317.475L248.118 319.262L245.379 339.561L242.986 357.25L250.993 365.257L246.219 370.03L243.086 387.978L243.092 388.019H115.214C110.041 384.668 104.942 381.213 99.918 377.655L100.97 369.777C100.97 369.777 104.591 355.71 103.069 354.029C101.552 352.347 98.2248 349.343 98.2248 349.343L102.416 339.561L102.998 338.203L102.022 317.963L101.999 317.475L101.676 310.803L101.635 309.945L101.57 308.657L100.329 282.979L100.306 282.556L99.9298 274.773L99.577 267.372L99.4241 264.25L97.9134 233.017L96.8905 211.807L95.9969 193.342L99.5888 192.125L138.006 179.069L153.919 158.382L187.039 158.629L203.253 158.752L230.306 183.842L236.461 186.781L236.508 186.805L240.923 188.915L252.116 194.259L263.95 199.914L270.022 202.813L270.151 202.877Z" fill="#CACACA"/>
<path d="M158.199 327.764C155.772 328.704 153.168 329.101 150.571 328.926C147.975 328.751 145.448 328.009 143.168 326.752C140.889 325.494 138.914 323.753 137.38 321.65C135.847 319.546 134.794 317.132 134.294 314.578L71.6523 305.691L93.4284 281.058L149.576 293.274C153.923 292.719 158.322 293.779 161.94 296.251C165.558 298.724 168.144 302.437 169.207 306.689C170.27 310.94 169.736 315.433 167.708 319.318C165.679 323.202 162.296 326.207 158.199 327.764V327.764Z" fill="#FFB6B6"/>
<path d="M130.269 282.979C129.109 281.508 127.466 280.494 125.631 280.117L99.9298 274.773L97.2903 274.226C95.7563 273.906 94.3072 273.266 93.0379 272.347C91.7685 271.428 90.7075 270.251 89.9243 268.894C89.1118 267.469 88.6203 265.885 88.484 264.25C88.3615 262.764 88.5394 261.269 89.0072 259.853L97.9133 233.017L103.692 215.604C104.392 213.504 104.806 211.319 104.921 209.109C105.243 202.991 103.35 196.961 99.5887 192.125C96.2822 187.874 91.6961 184.8 86.5079 183.356C81.3197 181.912 75.8047 182.175 70.7776 184.107C70.6306 184.16 70.4777 184.218 70.3308 184.277C66.7256 185.717 63.5046 187.975 60.9223 190.874C58.34 193.772 56.4672 197.232 55.452 200.978L49.1736 223.817L36.2229 270.899C34.9265 275.616 34.6646 280.558 35.4552 285.385C36.2457 290.213 38.07 294.813 40.8032 298.871C43.5364 302.928 47.1139 306.347 51.291 308.893C55.4681 311.44 60.1461 313.054 65.0047 313.625L102.022 317.963L119.153 319.974C121.134 320.212 123.131 319.695 124.748 318.526C126.365 317.357 127.482 315.622 127.877 313.666C127.904 313.559 127.926 313.451 127.941 313.343L128.353 310.803L131.839 289.417L131.874 289.199C132.056 288.108 132.007 286.99 131.73 285.919C131.454 284.848 130.956 283.847 130.269 282.979Z" fill="#CACACA"/>
<path d="M198.1 328.449C200.635 329.038 203.269 329.063 205.815 328.524C208.361 327.985 210.758 326.894 212.837 325.328C214.917 323.762 216.627 321.759 217.848 319.46C219.07 317.162 219.772 314.624 219.906 312.024L280.669 294.392L255.637 273.076L201.773 293.088C197.391 293.152 193.185 294.821 189.952 297.779C186.719 300.737 184.683 304.778 184.23 309.137C183.777 313.496 184.939 317.869 187.495 321.429C190.051 324.988 193.825 327.486 198.1 328.449V328.449Z" fill="#FFB6B6"/>
<path d="M310.837 254.95L281.938 188.439C279.574 182.979 275.353 178.532 270.022 175.888C268.374 175.077 266.644 174.446 264.861 174.007C264.584 173.937 264.308 173.872 264.032 173.819C260.25 172.994 256.328 173.066 252.58 174.031C249.154 174.911 245.952 176.504 243.182 178.705C240.413 180.906 238.139 183.666 236.508 186.805C236.49 186.831 236.474 186.859 236.461 186.887C234.639 190.411 233.671 194.314 233.633 198.281C233.595 202.247 234.489 206.168 236.243 209.726L236.602 210.443L257.024 251.458C257.722 252.861 258.115 254.395 258.177 255.96C258.24 257.525 257.971 259.086 257.388 260.54C256.806 261.993 255.922 263.308 254.795 264.396C253.669 265.484 252.325 266.322 250.852 266.854L249.417 267.372L223.622 276.689C221.686 277.38 220.094 278.795 219.181 280.636C218.267 282.477 218.104 284.6 218.725 286.559L218.79 286.765L226.02 309.904C226.056 310.009 226.091 310.115 226.132 310.221C226.196 310.421 226.274 310.615 226.367 310.803C227.136 312.526 228.494 313.919 230.198 314.731C231.901 315.542 233.839 315.719 235.661 315.23L249.582 311.555L252.421 310.803L288.369 301.309C293.099 300.059 297.503 297.802 301.28 294.692C305.056 291.582 308.116 287.692 310.25 283.29C312.384 278.887 313.541 274.075 313.642 269.184C313.744 264.293 312.787 259.437 310.837 254.95L310.837 254.95Z" fill="#CACACA"/>
<path d="M26.3939 310.803C34.7241 320.963 43.7081 330.57 53.2886 339.561H318.985C321.545 339.565 323.999 340.584 325.808 342.395C327.618 344.206 328.635 346.66 328.638 349.22L329.02 416.548C345.365 409.038 360.672 399.449 374.562 388.019C378.418 384.85 382.155 381.545 385.772 378.102C388.141 375.856 390.454 373.555 392.709 371.2C409.618 353.585 423.311 333.143 433.166 310.803H26.3939Z" fill="#3F3D56"/>
<path d="M392.709 371.2C390.458 373.557 388.145 375.858 385.772 378.102H329.214V371.2H392.709Z" fill="white"/>
<path d="M269.622 150.463C269.343 149.948 268.929 149.517 268.426 149.217C267.922 148.917 267.346 148.758 266.759 148.759H74.0344C73.1703 148.761 72.3423 149.105 71.7319 149.717C71.1215 150.329 70.7783 151.157 70.7776 152.021V267.372H270.022V152.021C270.025 151.476 269.887 150.94 269.622 150.463Z" fill="#3F3D56"/>
<path d="M70.603 264.249V278.483C70.603 279.073 70.7193 279.658 70.9451 280.203C71.1709 280.748 71.5019 281.243 71.9191 281.661C72.3364 282.078 72.8318 282.409 73.377 282.635C73.9221 282.86 74.5065 282.977 75.0966 282.977H148.636V311.069H147.659C147.583 311.069 147.507 311.084 147.436 311.113C147.365 311.142 147.301 311.185 147.247 311.24C147.192 311.294 147.149 311.358 147.12 311.429C147.091 311.5 147.076 311.576 147.076 311.652V313.606C147.076 313.683 147.091 313.759 147.12 313.83C147.149 313.9 147.192 313.965 147.247 314.019C147.301 314.073 147.365 314.116 147.436 314.146C147.507 314.175 147.583 314.19 147.659 314.19H193.312C193.389 314.19 193.464 314.175 193.535 314.146C193.606 314.116 193.67 314.073 193.725 314.019C193.779 313.965 193.822 313.9 193.851 313.83C193.881 313.759 193.896 313.683 193.896 313.606V311.652C193.896 311.576 193.881 311.5 193.851 311.429C193.822 311.358 193.779 311.294 193.725 311.24C193.67 311.185 193.606 311.142 193.535 311.113C193.464 311.084 193.389 311.069 193.312 311.069H192.335V282.977H265.875C266.465 282.977 267.049 282.86 267.594 282.635C268.14 282.409 268.635 282.078 269.052 281.661C269.469 281.243 269.8 280.748 270.026 280.203C270.252 279.658 270.368 279.073 270.368 278.483V264.249H70.603Z" fill="#3F3D56"/>
<path d="M168.072 223.394C178.236 223.394 186.476 215.154 186.476 204.99C186.476 194.826 178.236 186.586 168.072 186.586C157.908 186.586 149.668 194.826 149.668 204.99C149.668 215.154 157.908 223.394 168.072 223.394Z" fill="#FFB802"/>
<path d="M214.963 101.7H212.023C212.023 79.4789 190.031 64.9175 167.846 64.9057C164.619 64.8963 161.399 65.2094 158.234 65.8404C154.672 66.5518 150.974 67.4042 147.341 68.5152C133.015 72.9066 119.729 81.3953 119.729 101.7C119.726 105.643 120.228 109.571 121.222 113.387L118.377 114.128C117.322 110.069 116.788 105.893 116.79 101.7C116.79 83.9584 124.073 71.772 136.654 64.506C143.267 60.6849 151.338 58.2217 160.585 57.0225C164.433 56.5106 168.324 56.3924 172.196 56.6698C197.709 58.5039 214.963 78.068 214.963 101.7Z" fill="#FFB802"/>
<path d="M219.86 103.328L218.702 94.7691C218.416 92.6076 217.375 90.6168 215.762 89.1492C214.727 88.1924 213.488 87.4835 212.139 87.0762C210.789 86.6688 209.365 86.5736 207.973 86.7977L206.133 87.0505C205.63 87.1168 205.144 87.2824 204.705 87.5377C204.266 87.793 203.882 88.1329 203.576 88.5378C203.267 88.9413 203.04 89.402 202.91 89.8934C202.78 90.3848 202.749 90.8971 202.817 91.4007L205.492 111.118C205.602 111.921 205.963 112.67 206.522 113.257C207.081 113.845 207.811 114.242 208.608 114.392C208.844 114.439 209.084 114.463 209.325 114.463C209.498 114.463 209.671 114.451 209.842 114.427L211.9 114.151C213.154 113.985 214.362 113.563 215.447 112.913C216.533 112.263 217.474 111.398 218.214 110.371C218.372 110.148 218.525 109.918 218.666 109.683C219.768 107.76 220.189 105.521 219.86 103.328Z" fill="#FFB802"/>
<path d="M204.946 87.4091C204.406 87.6713 203.936 88.0582 203.576 88.5378C203.267 88.9413 203.04 89.402 202.91 89.8934C202.78 90.3848 202.749 90.8971 202.817 91.4007L205.492 111.118C205.602 111.921 205.963 112.67 206.522 113.258C207.081 113.845 207.811 114.242 208.608 114.392L204.946 87.4091Z" fill="white"/>
<path d="M219.86 103.328L218.702 94.7691C218.416 92.6076 217.375 90.6168 215.762 89.1492L218.425 108.766C218.465 109.081 218.546 109.389 218.666 109.683C219.768 107.76 220.189 105.521 219.86 103.328Z" fill="#3F3D56"/>
<path d="M210.707 109.496C211.511 109.386 211.915 107.472 211.61 105.22C211.304 102.968 210.405 101.231 209.6 101.34C208.796 101.449 208.392 103.363 208.697 105.615C209.003 107.867 209.903 109.605 210.707 109.496Z" fill="#3F3D56"/>
<path d="M208.943 98.914C209.748 98.8049 210.152 96.8908 209.846 94.6388C209.541 92.3867 208.641 90.6495 207.837 90.7586C207.033 90.8678 206.628 92.7818 206.934 95.0339C207.239 97.2859 208.139 99.0231 208.943 98.914Z" fill="#3F3D56"/>
<path d="M220.113 103.029C220.917 102.92 221.321 101.006 221.016 98.7538C220.71 96.5017 219.811 94.7646 219.006 94.8737C218.202 94.9828 217.798 96.8969 218.103 99.1489C218.409 101.401 219.308 103.138 220.113 103.029Z" fill="#3F3D56"/>
<path d="M167.846 64.9057C164.619 64.8963 161.399 65.2094 158.234 65.8404C154.672 66.5517 150.974 67.4041 147.341 68.5152C141.668 68.2389 137.494 66.7692 136.654 64.5059C143.267 60.6848 151.338 58.2217 160.585 57.0225C164.433 56.5105 168.324 56.3923 172.196 56.6697C172.606 56.91 172.96 57.2344 173.236 57.6214C173.511 58.0084 173.702 58.4494 173.795 58.9154C174.071 60.9612 171.737 63.1362 167.846 64.9057Z" fill="#3F3D56"/>
<path d="M130.316 122.099L125.79 103.281C125.628 102.603 125.27 101.986 124.76 101.511C124.249 101.035 123.61 100.72 122.921 100.607C122.727 100.576 122.53 100.561 122.333 100.56C122.056 100.561 121.78 100.594 121.51 100.66L119.758 101.083C117.503 101.628 115.556 103.046 114.345 105.026C113.133 107.005 112.757 109.384 113.298 111.641L115.326 120.083C115.789 122.07 116.892 123.85 118.465 125.15C118.72 125.356 118.987 125.546 119.265 125.72C120.25 126.338 121.349 126.752 122.497 126.937C123.645 127.123 124.819 127.077 125.949 126.802L127.694 126.379C128.156 126.271 128.59 126.069 128.97 125.785C129.535 125.373 129.964 124.803 130.204 124.147C130.444 123.49 130.483 122.778 130.316 122.099Z" fill="#FFB802"/>
<path d="M120.827 118.598C121.616 118.408 121.825 116.463 121.293 114.253C120.761 112.043 119.69 110.406 118.901 110.596C118.112 110.786 117.904 112.731 118.435 114.941C118.967 117.15 120.038 118.788 120.827 118.598Z" fill="#3F3D56"/>
<path d="M114.948 120.361C115.737 120.171 115.946 118.226 115.414 116.017C114.883 113.807 113.812 112.17 113.023 112.36C112.234 112.55 112.025 114.495 112.557 116.704C113.088 118.914 114.159 120.551 114.948 120.361Z" fill="#3F3D56"/>
<path d="M130.604 122.028L126.078 103.211C125.902 102.476 125.512 101.81 124.959 101.296C124.406 100.781 123.713 100.441 122.968 100.319L129.147 126.02C129.757 125.573 130.221 124.955 130.48 124.245C130.74 123.535 130.783 122.763 130.604 122.028Z" fill="white"/>
<path d="M114.321 107.914C114.196 107.39 113.961 106.9 113.633 106.474C112.996 108.121 112.88 109.925 113.298 111.641L115.326 120.083C115.789 122.07 116.892 123.85 118.465 125.15L114.321 107.914Z" fill="#3F3D56"/>
</g>
<defs>
<clipPath id="clip0_18_147">
<rect width="500" height="435.506" fill="white"/>
</clipPath>
</defs>
</svg>
